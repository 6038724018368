import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Link} from 'react-router-dom';
import {OverlayContext} from './../../contexts/OverlayContext';
import styled from 'styled-components';

import {BITTERSWEET, OzContainer, OzRow, OzCol, OzCard, OzTable, OzCopyable, OzProjectTag, OzUserTag} from '@ozwol/webui';

import HotfoldersService from '../../services/HotfoldersService';
import ProjectsService from '../../services/ProjectsService';
import UsersService from '../../services/UsersService';

import InsiderHotfoldersModalNew from './InsiderHotfoldersModalNew';
import InsiderHotfoldersModalPublish from './InsiderHotfoldersModalPublish';
import InsiderHotfoldersModalBulkEdit from './InsiderHotfoldersModalBulkEdit';

import Filters from '../../components/Filters';
import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';
import NoResult from '../../components/NoResult';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';

import {formatError} from '../../helpers';

const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(3),
  & table tr td:nth-child(4),
  & table tr td:nth-child(5){
    word-break: break-all;
    white-space: unset;
  }
`;

const Icon = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
  & > span{
    font-size: 20px;
  }
`;

const Scripts = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
`;
const Script = styled.div`
  display: inline-flex;
  align-items: center;
  height: 20px;
  white-space: nowrap;

  &:not(:first-child){
    margin-left: 5px;
  }
`;


const InsiderHotfoldersList = ({refScrollerPage}) => {
  const {setOverlay} = useContext(OverlayContext);

  const [page, setPage] = useState(0);
  const [list, setList] = useState(null);

  // const [listUsers, setListUsers] = useState([]);
  const [listProjects, setListProjects] = useState([]);
  const [filterSearchProjects, setFilterSearchProjects] = useState([]);
  const [filterSearchUsers, setFilterSearchUsers] = useState([]);

  const [selected, setSelected] = useState([]);
  const [isBulkeditable, setIsBulkeditable] = useState(false);

  // const [errorMessage, setErrorMessage] = useState(null);
  const [fetchError, setFetchError] = useState(false);

  const DEFAULT_FILTERS = {
    "search": "",
    "active": true,
    "projectUuid": null,
    "userUuid": null
  };
  const [filters, setFilters] = useState(
    localStorage.getItem("ozwolConsoleFilterInsiderHotfolders"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ? 
      JSON.parse(localStorage.getItem("ozwolConsoleFilterInsiderHotfolders"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))))
    : 
      DEFAULT_FILTERS
    );
  const [filtersSearch, setFiltersSearch] = useState({
    "userUuid": "",
    "projectUuid": ""
  });

  const changeFilters = useCallback((name, value) => {
    setPage(0);
    let tempFilters = {...filters};
    tempFilters[name] = value;
    setFilters(tempFilters);
  }, [filters]);

  const searchFilters = useCallback((name, value) => {
    let tempFiltersSearch = {...filtersSearch};
    tempFiltersSearch[name] = value;
    setFiltersSearch(tempFiltersSearch);

    if(value.length > 2 || value.length === 0){
      switch(name){
        case "projectUuid":
          ProjectsService.adminList({
            "search": value.length > 2 ? value : ""
          }).then((response) => {
            setFilterSearchProjects(response.result);
          });
          break;
        case "userUuid":
          UsersService.adminList({
            "search": value.length > 2 ? value : ""
          }).then((response) => {
            setFilterSearchUsers(response.result);
          });
          break;
        default:
          break;
      }
    }

  }, [filtersSearch]);

  const getList = useCallback(() => {
    HotfoldersService.adminList({
      "search": filters.search.length > 2 ? filters.search : "",
      "active": filters.active,
      "projectUuid": filters.projectUuid,
      "userUuid": filters.userUuid
    }).then((response) => {
      localStorage.setItem("ozwolConsoleFilterInsiderHotfolders"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(filters));
      setList(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });

  }, [filters]); //page, filters

  useEffect(() => {
    getList();
  }, [getList, page, filters]); 

  useEffect(() => {
    UsersService.adminList().then((response) => {
      setFilterSearchUsers(response.result);
      // setListUsers(response.result);
    });
    ProjectsService.adminList().then((response) => {
      setFilterSearchProjects(response.result);
      setListProjects(response.result);
    });
  }, []);
  

  useEffect(() => {
    let script = null;
    let isOk = true;
    if(selected.length > 0){
      selected.forEach((item) => {
        let projs = list.filter(item2 => item2.uuid === item);
        if(projs.length === 1){
          if(projs[0].scriptNames && projs[0].scriptNames.length === 1){
            if(script !== null){
              if(script !==  projs[0].scriptNames[0]){
                isOk = false;
              }
            }else{
              script = projs[0].scriptNames[0];
            }            
          }else{
            isOk = false;
          }
        }else{
          isOk = false;    
        }
      });      
      setIsBulkeditable(isOk);
    }else{
      setIsBulkeditable(false);
    }
  }, [list, selected]); 

  const deleteItems = () => {
    // setErrorMessage(null);

    Promise.all(selected.map((singleItem) => {
      return new Promise((resolve, reject) => { 
        HotfoldersService.adminDelete(singleItem).then((response) => {
          // window.location.replace("/insider/hotfolders/"+response.uuid);
          resolve();
        }).catch((error) => {
          // setErrorMessage(formatError(error));
          reject(error);
        });
      });
    })).then((responses) => {
      window.location.replace("/insider/hotfolders/");
    }).catch((response) => {
      // setErrorMessage(response.message)
    });

  };


  return (
    <OzContainer size="extra">
      <Meta title={"Hotfolders - Insider"} />
      <PageHeader
        breadcrumbsIcon="folder"
        breadcrumbsText={["Hotfolders"]}
        refScrollerPage={refScrollerPage}
        buttons={[
          {
            "icon": "edit",
            "text": "Bulk edit" + (selected.length ? " ("+selected.length+")" : ""),
            "onClick": () => setOverlay(<InsiderHotfoldersModalBulkEdit listHotfolders={selected} />),
            "disabled": !isBulkeditable
          },{
            "icon": "delete",
            "text": "Delete" + (selected.length ? " ("+selected.length+")" : ""),
            "prompt": "DELETE",
            "onClick": () => deleteItems(),
            "disabled": selected.length === 0
          },
          {
            "icon": "publish",
            "text": "Publish" + (selected.length ? " ("+selected.length+")" : ""),
            "onClick": () => setOverlay(<InsiderHotfoldersModalPublish sourceItems={list.filter(item => selected.includes(item.uuid))}/>),
            "disabled": selected.length === 0
          },{
            "icon": "add",
            "text": "Add",
            "onClick": () => setOverlay(<InsiderHotfoldersModalNew />)
          }          
        ]}
      />

      <OzRow>
        <OzCol>
          <HealthyWrapper error={fetchError} loading={!list}>
            <OzCard
              headerLeft={
                <CardHeaderTitle 
                  title={"Hotfolders"}
                />
              }
              headerSeparator={true}
            >
              <Filters
                onChange={(...args) => changeFilters(...args)}
                onSearch={(...args) => searchFilters(...args)}
                fields={
                  [
                    {
                      "label": "Search",
                      "name": "search",
                      "size": 4,
                      "type": "text",
                      "placeholder": null,
                      "value": filters.search
                    },{
                      "label": "Status",
                      "name": "active",
                      "size": 2,
                      "type": "select",
                      "placeholder": null,
                      "value": filters.active,
                      "options": [
                        {
                          "name": <i>All</i>,
                          "value": null
                        },
                        {
                          "name": "Enabled",
                          "value": true
                        },{
                          "name": "Disabled",
                          "value": false
                        }
                      ]
                    },{
                      "label": "Project",
                      "name": "projectUuid",
                      "size": 6,
                      "type": "searchableselect",
                      "placeholder": null,
                      "value": filters.projectUuid,
                      "options": [{
                        "name": <i>All</i>,
                        "value": null,
                      }].concat(filterSearchProjects.map((item) => ({
                        "name": <OzProjectTag
                            uuid={item.uuid}
                            name={item.name}
                            color={item.color}
                            direction={"horizontal"}
                          />,
                        "value": item.uuid,
                      })))
                    },{
                      "label": "Owner",
                      "name": "userUuid",
                      "size": 6,
                      "type": "searchableselect",
                      "placeholder": null,
                      "value": filters.userUuid,
                      "options": [{
                        "name": <i>All</i>,
                        "value": null,
                      }].concat(filterSearchUsers.map((item) => ({
                        "name": <OzUserTag
                            name={item.givenName + " " + item.familyName}
                            company={item.company}
                            direction={"horizontal"}
                          />,
                        "value": item.uuid,
                      })))
                    },{
                      "size": 2,
                      "type": "clear",
                      "callback": () => {
                        setFilters(DEFAULT_FILTERS);
                        // localStorage.removeItem("ozwolConsoleFilterInsiderHotfolders"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))
                      }
                    }
                  ]
                }
              />
              <hr/>

            
              {list && list.length > 0 ?
                <>
                  <TableWrapper>
                    <OzTable
                      selectable={true}
                      selected={selected}
                      onSelect={(ids) => {
                        let tempSelected = [...selected]
                        ids.forEach(id => {
                          setSelected(tempSelected);
                          if(!selected.includes(id)){
                            tempSelected.push(id);
                          }else{
                            tempSelected = tempSelected.filter(e => e !== id);
                          }                    
                        });
                        setSelected(tempSelected);
                      }}
                      columns={[
                        "Project",
                        "Path",
                        "Scripts",
                        "Description"
                      ]}
                      values={
                        list.map(item => ({
                          style: !item.active ? {
                            background: BITTERSWEET+"33",
                            backgroundHover: BITTERSWEET+"55"
                          } : {},
                          id: item.uuid,
                          values: [
                            listProjects.filter(val => val.uuid === item.projectUuid).length > 0 ?
                              listProjects.filter(val => val.uuid === item.projectUuid).slice(0,1).map(project =>
                                <OzProjectTag
                                  key={project.uuid}
                                  name={project.name}
                                  uuid={project.uuid}
                                  color={project.color}
                                />
                              )
                            : "-",
                            // item.path.split('/')[item.path.split('/').length - 2],
                            <div onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                              <OzCopyable>{item.path}</OzCopyable>
                            </div>,
                            <Scripts>{item.scriptsList && item.scriptsList.length > 0 ? item.scriptsList.map(item => 
                              <Script key={item.uuid}>{(item.isLazy ? <Icon><span className="material-symbols-outlined">bedtime</span></Icon> : "")}{item.name}</Script>
                            ).reduce((acc, x) => acc === null ? [x] : [acc, ', ', x], null) : "-"}
                            </Scripts>,
                            item.description
                          ],
                          link: ({children}) => (<Link to={"/insider/hotfolders/"+item.uuid}>{children}</Link>)
                        }))
                      }
                    />
                  </TableWrapper>
                </>
              : <NoResult />}           
            </OzCard>          
          </HealthyWrapper>    
        </OzCol>
      </OzRow>
    </OzContainer>
  )
}

export default InsiderHotfoldersList;
