import React from 'react';
import styled from 'styled-components';

import ErrorMessage from './ErrorMessage';

import {OzCard, OzRow, OzCol} from "@ozwol/webui";

const Text = styled.div`
  height: 30px;
  color: red;
  text-align: center;
  margin-bottom: 20px;
`;

const Error = ({children}) => {
	return (
    <OzRow>
      <OzCol>
        <OzCard>
          <Text>
            <ErrorMessage>
              {children}
            </ErrorMessage>
          </Text>
        </OzCard>
      </OzCol>
    </OzRow>
	)
}

export default Error;
