import React, {useContext} from 'react';
import {OverlayContext} from './../contexts/OverlayContext';

import {OzContainer, OzRow, OzCol, OzButton, OzCard} from '@ozwol/webui';

import CardHeaderTitle from './../components/CardHeaderTitle';


const PromptedButton = ({
	buttonText,
	buttonSize, 
	buttonVariant="bittersweet-o", 
	buttonNoMinWidth = false, 
	prompt,
	onConfirm, 
	disabled,
	children = null
}) => {
  const {setOverlay} = useContext(OverlayContext);
  return (
    <>
      <OzButton disabled={disabled} variant={buttonVariant} size={buttonSize} noMinWidth={buttonNoMinWidth} onClick={() => setOverlay(
				<OzContainer size="small">
					<OzCard
            headerLeft={
              <CardHeaderTitle 
								title={
									prompt === "DELETE" ?
										<>
											Are you sure?
										</>
									: null
								}
							/>
            }
						footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => setOverlay(null)}>Back</OzButton>}
						footerRight={<OzButton onClick={() => {setOverlay(null); onConfirm();}} variant={"bittersweet"}>Confirm</OzButton>}
					>
						<OzRow>
							<OzCol widthmd="12">
								{prompt === "DELETE" ?
									<>
										This operation cannot be undone.							
									</>
								: null}
							</OzCol>
						</OzRow>
					</OzCard>
				</OzContainer>
			)}>{buttonText}</OzButton>
    </>
  )
}

export default PromptedButton;
