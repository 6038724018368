//TODO pulizia file
import React, { useState, useContext, useEffect} from 'react';
import styled from 'styled-components';

import {OverlayContext} from '../../contexts/OverlayContext';

import {OzContainer, OzButton, OzSelect, OzCol, OzRow, OzInput, OzCard} from "@ozwol/webui";

import HotfoldersService from '../../services/HotfoldersService';
// import ProjectsService from '../../services/ProjectsService';
import ScriptsService from './../../services/ScriptsService';

import CardHeaderTitle from '../../components/CardHeaderTitle';
import ErrorMessage from '../../components/ErrorMessage';
import HealthyWrapper from '../../components/HealthyWrapper';

import {formatError, testValidField} from '../../helpers';

const ScriptPresets = styled.div`
  padding-top: 43px;

  & > *{
    margin-right: 5px;
  }
`;


const InsiderHotfoldersActionsModalNew = ({path, hotfolderUuid}) => {
  const {setOverlay} = useContext(OverlayContext);
  const [item, setItem] = useState({
    destination: path.replace("HOTFOLDERS/INPUT/","HOTFOLDERS/OUTPUT/")+"{path}/{image}",
    fileNameRegexp: "(?P<image>.*)\\.(?P<ext>.*)",
    pathNameRegexp: "^(?P<path>.*)?",
    outputFormat: {imageMetadataKeep: true}
  });

  const [searchScripts, setSearchScripts] = useState("");
  const [listScripts, setListScripts] = useState([]);

  const [disabled, setDisabled] = useState(true);
  // const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const save = () => {
    HotfoldersService.adminCreateActions(item).then((response) => {
      window.location.replace("/insider/hotfolders/"+response.hotfolderUuid+"/"+response.uuid);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  };

  useEffect(() => {
    ScriptsService.adminList({
      "search": searchScripts.length > 2 ? searchScripts : "",
    }).then((response) => {
      setListScripts(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, [searchScripts]);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item){
      if(!testValidField(item.scriptUuid, true)){
        valid = false;
      }
      if(!testValidField(item.fileNameRegexp, true)){
        valid = false;
      }
      if(!testValidField(item.destination, true)){
        valid = false;
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item]);

  useEffect(() => {
    let tempItem = {...item};
    tempItem.hotfolderUuid = hotfolderUuid;
    setItem(tempItem);    
  }, [item, hotfolderUuid]);

  return (
    <OzContainer>
      
      <HealthyWrapper error={fetchError}>
        <OzCard
          headerLeft={
            <CardHeaderTitle title={"Add action"} />
          }
          headerSeparator={true}
          footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => setOverlay(null)}>Back</OzButton>}
          footerRight={<OzButton onClick={() => save()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton>}
        >

          <OzRow>
            <OzCol widthmd="6">
              <OzSelect
                label="Script"
                width="100%"
                placeholder={"Script"}
                values={listScripts.map((item) => ({
                  "name": item.name,
                  "value": item.uuid,
                }))}
                selected={item && item.scriptUuid && listScripts.length > 0 ? item.scriptUuid : null}
                highlighted={item && item.scriptUuid}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.scriptUuid = val;
                  setItem(tempItem);
                }}
                searchable={true}
                search={searchScripts}
                onSearch={(val) => setSearchScripts(val)}
                maxHeight={"200px"}
                required
              />
            </OzCol>
            <OzCol widthmd="6">
              <ScriptPresets>
                {listScripts.filter(script => script.preset).map(script => 
                  <OzButton variant="push-black" onClick={() => {
                    let tempItem = {...item};
                    tempItem.scriptUuid = script.uuid;
                    setItem(tempItem);
                  }}>{script.name}</OzButton>
                )}
              </ScriptPresets>
            </OzCol>
            <OzCol widthmd="12">
              <OzInput
                label="Description"
                width="100%"
                value={item && item.description ? item.description : ""}
                highlighted={item && item.description && item.description.length > 0}
                placeholder="Description"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.description = val;
                  setItem(tempItem);
                }}
              />
            </OzCol>
            <OzCol widthmd="6">
              <OzInput
                label="Path name regexp "
                width="100%"
                value={item && item.pathNameRegexp  ? item.pathNameRegexp  : ""}
                highlighted={item && item.pathNameRegexp && item.pathNameRegexp !== "^(?P<path>.*)?"}
                placeholder="Path name regexp"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.pathNameRegexp  = val;
                  setItem(tempItem);
                }}
              />
            </OzCol>
            <OzCol widthmd="6">
              <OzInput
                label="File name regexp "
                width="100%"
                value={item && item.fileNameRegexp  ? item.fileNameRegexp  : ""}
                highlighted={item && item.fileNameRegexp && item.fileNameRegexp !== "(?P<image>.*)\\.(?P<ext>.*)"}
                placeholder="File name regexp"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.fileNameRegexp  = val;
                  setItem(tempItem);
                }}
                required
              />
            </OzCol>
            <OzCol widthmd="12">
              <OzInput
                label="Destination"
                width="100%"
                value={item && item.destination ? item.destination : ""}
                highlighted={item && item.destination && item.destination !== path.replace("HOTFOLDERS/INPUT/","HOTFOLDERS/OUTPUT/")+"{path}/{image}"}
                placeholder="Destination"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.destination = val;
                  setItem(tempItem);
                }}
                required
              />
            </OzCol>
            
          </OzRow>
          <ErrorMessage>
            {errorMessage}
          </ErrorMessage>
          
        
        </OzCard>
      </HealthyWrapper>
    </OzContainer>
  );

}
export default InsiderHotfoldersActionsModalNew;
