import React, {useState, useEffect, useCallback, useContext} from 'react';
// import styled from 'styled-components';
import {OverlayContext} from '../../contexts/OverlayContext';
import {Link, useParams} from 'react-router-dom';

import {OzCard, OzRow, OzCol, OzSelect, OzInput, OzCheckbox, OzButton} from '@ozwol/webui';

import ScriptsService from './../../services/ScriptsService';

import HotfoldersService from '../../services/HotfoldersService';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';
import Success from '../../components/Success';
import PromptedButton from '../../components/PromptedButton';
import ErrorMessage from '../../components/ErrorMessage';


import ModalParseRegex from './../../chunks/ModalParseRegex';



import {formatError, clearObject, testValidField} from '../../helpers';

// const LabelSpacer = styled.div`
//   height: 43px;
// `;

const InsiderHotfoldersActionsSingle = ({itemUuid, listItems}) => {
  const {actionUuid} = useParams();
  const {setOverlay} = useContext(OverlayContext);
  const [item, setItem] = useState();
  const [originalItem, setOriginalItem] = useState(null);
  const [searchScripts, setSearchScripts] = useState("");
  const [listScripts, setListScripts] = useState([]);
  const [originalScriptUuid, setOriginalScriptUuid] = useState([]);
  
  const [errorPathNameRegex, setErrorPathNameRegex] = useState(null);
  const [errorFileNameRegex, setErrorFileNameRegex] = useState(null);

  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);

  const [fetchError, setFetchError] = useState(false);


  const getItem = useCallback(() => {
    console.log(itemUuid);
    HotfoldersService.adminGetActions(itemUuid).then((response) => {
      setItem(response);
      setOriginalItem(response);
      setOriginalScriptUuid(response.scriptUuid);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, [itemUuid]);

  const deleteItem = useCallback(() => {
    HotfoldersService.adminDeleteActions(item.uuid).then((response) => {
      window.location.replace("/insider/hotfolders/"+item.hotfolderUuid);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [item]);


  useEffect(() => {
    if(item && originalItem && item.pathNameRegexp && originalItem.pathNameRegexp){
      if(item.pathNameRegexp !== originalItem.pathNameRegexp){
        HotfoldersService.checkRegex({
          "regex": item.pathNameRegexp,
          "text": ""
        }).then((response) => {
          setErrorPathNameRegex(null);
        }).catch((error) => {
          setErrorPathNameRegex(formatError(error));
        });    
      }else{
        setErrorPathNameRegex(null);
      }
    }
    if(item && originalItem && item.fileNameRegexp && originalItem.fileNameRegexp){
      if(item.fileNameRegexp !== originalItem.fileNameRegexp){
        HotfoldersService.checkRegex({
          "regex": item.fileNameRegexp,
          "text": ""
        }).then((response) => {
          setErrorFileNameRegex(null);
        }).catch((error) => {
          setErrorFileNameRegex(formatError(error));
        });  
      }else{
        setErrorFileNameRegex(null);
      }
    }
  }, [item, originalItem]);


  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item && item !== originalItem){
      if(!testValidField(item.scriptUuid, true)){
        valid = false;
      }
      if(errorPathNameRegex){
        valid = false;
      }
      if(!testValidField(item.fileNameRegexp, true) || errorFileNameRegex){
        valid = false;
      }
      if(!testValidField(item.destination, true)){
        valid = false;
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item, originalItem, errorPathNameRegex, errorFileNameRegex]);

  const updateItem = useCallback(() => {
    setErrorMessage(null);
    setDisabled(true);
    if(item && originalItem && item.scriptUuid !== originalItem.scriptUuid){
      item.scriptValues = {};
    }
    HotfoldersService.adminUpdateActions(itemUuid, clearObject(item, [
      "scriptUuid",
      "active",
      "description",
      "pathNameRegexp",
      "fileNameRegexp",
      "destination",
      "outputToCdn",
      "outputIsTemporary",
      "scriptValues"
    ])).then((response) => {
      setOriginalScriptUuid(response.scriptUuid);
      setOverlay(<Success>Your data has been successfully saved!</Success>);
      setTimeout(() => {
        setOverlay(null);
      }, 2000);
      setOriginalItem(item);

      if(actionUuid && (item && originalItem && item.scriptUuid !== originalItem.scriptUuid)){
        window.location.replace("/insider/hotfolders/"+item.hotfolderUuid+"/"+actionUuid);
      }
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [itemUuid, item, originalItem, setOverlay, actionUuid]);

  useEffect(() => {
    getItem();
  }, [itemUuid, getItem]);

  // useEffect(() => {    
  //   ScriptsService.list().then((response) => {
  //     setFilterSearchScripts(response.result);
  //     setListScripts(response.result);
  //   });
  // }, []);

  useEffect(() => {
    ScriptsService.adminList({
      "search": searchScripts.length > 2 ? searchScripts : "",
    }).then((response) => {
      setListScripts(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, [searchScripts]);

  const slackify = useCallback(() => {
    HotfoldersService.adminGet(item.hotfolderUuid).then((response1) => {
      ScriptsService.get(item.scriptUuid).then((response2) => {
        HotfoldersService.slackify(response1, item, response2);
      });
    });
  }, [item]);

  const copy = useCallback((itemUuid) => {
    let tempAction = {...listItems.filter(item => item.uuid === itemUuid)[0]};
    delete tempAction.uuid;
    tempAction.description = "Copy of " + tempAction.description;
    HotfoldersService.adminCreateActions(tempAction).then((response) => {
      window.location.replace("/insider/hotfolders/"+response.hotfolderUuid+"/"+response.uuid);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });    
  }, [listItems]);

  
  return (
    <>
      <HealthyWrapper error={fetchError} loading={!item}>
        {item && listScripts ?
          <OzCard
            collapsible={true}
            headerLeft={
              <>
                <CardHeaderTitle title={
                  <>
                    {item && item.scriptUuid && listScripts.length > 0 && listScripts.filter(x => x.uuid === item.scriptUuid).length > 0 ? 
                      listScripts.filter(x => x.uuid === item.scriptUuid)[0].name
                    : null}
                    {item && item.description ? " | " + item.description : ""}
                  </>
                } />
              </>
            }
            headerSeparator={true}
            headerRight={
              originalScriptUuid === item.scriptUuid ? 
                <>
                  <OzButton onClick={() => slackify()} icon="slack" variant={"push-black"}>Slackify</OzButton> &nbsp;
                  <OzButton onClick={() => copy(item.uuid)} icon="slack" variant={"push-black"}>Clone</OzButton> &nbsp;
                  
                  <OzButton 
                    variant={"silvertree-o"}
                    onClick={() => setOverlay(
                      <ModalParseRegex 
                        pathNameRegexp={item.pathNameRegexp} 
                        fileNameRegexp={item.fileNameRegexp} 
                        destination={item.destination} 
                        onSuccess={(val) => {
                          let tempItem = {...item};
                          tempItem.fileNameRegexp = val.file_name_regexp;
                          tempItem.pathNameRegexp = val.path_name_regexp;
                          tempItem.destination = val.destination;
                          setItem(tempItem);
                        }}
                      />
                    )}
                  >Regex</OzButton> &nbsp;
                  <Link to={"/insider/hotfolders/"+item.hotfolderUuid+"/"+itemUuid}><OzButton variant={"silvertree-o"}>Configure</OzButton></Link>
                </>
              :
                "Controls disabled until saved"
            }
            footerLeft={
              <PromptedButton 
                prompt="DELETE"
                variant="bittersweet-o" 
                buttonText={"Delete"}
                onConfirm={() => deleteItem()}
              />
            }
            footerRight={
              <OzButton onClick={() => updateItem()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton>
            }

          >
            <OzRow>
              <OzCol widthmd="12">
                <OzSelect
                  label="Script"
                  width="100%"
                  placeholder={"Script"}
                  values={listScripts.map((item) => ({
                    "name": item.name,
                    "value": item.uuid,
                  }))}
                  selected={item && item.scriptUuid && listScripts.length > 0 ? item.scriptUuid : null}
                  highlighted={item && originalItem && item.scriptUuid !== originalItem.scriptUuid}
                  onChange={(val) => {
                    let tempItem = {...item};
                    tempItem.scriptUuid = val;
                    setItem(tempItem);
                  }}
                  searchable={true}
                  search={searchScripts}
                  onSearch={(val) => setSearchScripts(val)}
                  maxHeight={"200px"}
                  required
                />
              </OzCol>
              <OzCol widthmd="12">
                <OzCheckbox
                  label="Enabled"
                  selected={item.active}
                  highlighted={item && originalItem && item.active !== originalItem.active}
                  onChange={(val) => {
                    let tempItem = {...item};
                    tempItem.active = val;
                    setItem(tempItem);
                  }}
                />
              </OzCol>
              <OzCol widthmd="12">
                <OzInput
                  label="Description"
                  width="100%"
                  value={item.description ? item.description : ""}
                  highlighted={item && originalItem && item.description !== originalItem.description}
                  placeholder="Description"
                  onChange={(val) => {
                    let tempItem = {...item};
                    tempItem.description = val;
                    setItem(tempItem);
                  }}
                />
              </OzCol>
            </OzRow>
            <hr/>
            <CardHeaderTitle title="Input" />
            <OzRow>
              <OzCol widthmd="6">
                <OzInput
                  label="Path Name RegExp"
                  width="100%"
                  value={item && item.pathNameRegexp ? item.pathNameRegexp : ""}
                  highlighted={item && originalItem && item.pathNameRegexp !== originalItem.pathNameRegexp}
                  placeholder="Path Name RegExp"
                  onChange={(val) => {
                    let tempItem = {...item};
                    tempItem.pathNameRegexp = val;
                    setItem(tempItem);
                  }}
                />
                {errorPathNameRegex ?
                  <ErrorMessage>
                    {errorPathNameRegex}
                  </ErrorMessage>
                : null}
              </OzCol>
              <OzCol widthmd="6">
                <OzInput
                  label="File Name RegExp"
                  width="100%"
                  value={item && item.fileNameRegexp ? item.fileNameRegexp : ""}
                  highlighted={item && originalItem && item.fileNameRegexp !== originalItem.fileNameRegexp}
                  placeholder="File Name RegExp"
                  onChange={(val) => {
                    let tempItem = {...item};
                    tempItem.fileNameRegexp = val;
                    setItem(tempItem);
                  }}
                  required
                />
                {errorFileNameRegex ?
                  <ErrorMessage>
                    {errorFileNameRegex}
                  </ErrorMessage>
                : null}
              </OzCol>
              <OzCol widthmd="12">
                {/* <OzButton onClick={() => toggleModalNew(true)} variant={"silvertree"}>Editor</OzButton> */}
              </OzCol>
            </OzRow>
            <hr/>
            <CardHeaderTitle title="Output" />
            <OzRow>
              <OzCol widthmd="12">
                <OzInput
                  label="Destination"
                  width="100%"
                  value={item.destination}
                  highlighted={item && originalItem && item.destination !== originalItem.destination}
                  placeholder="Destination"
                  onChange={(val) => {
                    let tempItem = {...item};
                    tempItem.destination = val;
                    setItem(tempItem);
                  }}
                  required
                />
              </OzCol>
              <OzCol widthmd="12">
                <OzCheckbox
                  label="Output to CDN"
                  selected={item.outputToCdn}
                  highlighted={item && originalItem && item.outputToCdn !== originalItem.outputToCdn}
                  onChange={(val) => {
                    let tempItem = {...item};
                    tempItem.outputToCdn = val;
                    setItem(tempItem);
                  }}
                />
              </OzCol>
              <OzCol widthmd="12">
                <OzCheckbox
                  label="Output is temporary"
                  selected={item.outputIsTemporary}
                  highlighted={item && originalItem && item.outputIsTemporary !== originalItem.outputIsTemporary}
                  onChange={(val) => {
                    let tempItem = {...item};
                    tempItem.outputIsTemporary = val;
                    setItem(tempItem);
                  }}
                />
              </OzCol>
            </OzRow>
            <ErrorMessage>
              {errorMessage}
            </ErrorMessage>
          
          </OzCard>
        : null}
      </HealthyWrapper>

    </>
  )
}

export default InsiderHotfoldersActionsSingle;
