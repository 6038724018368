import { CHETWODEBLUE } from '@ozwol/webui';
import React  from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 300px;
  border-radius: 5px;
  padding: 20px;
  color: #ffffff;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;
  // align-items: center;
  // flex-grow: 1;
  // flex-shrink: 1;
  background-color: ${CHETWODEBLUE};

  text-align: left;
  z-index: 999;
`;

const Close = styled.div`
  coloe: #fff;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;

const Popup = ({fnClose = ()=>{}, children}) => {
  return (
    <Wrapper>
      <Close onClick={fnClose}><span className="material-symbols-outlined">close</span></Close>
      {children}
    </Wrapper>
  
  )
}

export default Popup;
