import ApiCore from './ApiCore';
import {queryParams} from '../helpers';

class CoworkersService extends ApiCore {

  join(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('public/coworkers/joinTeam'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  list(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/coworkers'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  get(uuid) {
    return new Promise((resolve, reject) => {
      if(uuid){
        this.instance.get('backoffice/coworkers/'+uuid).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      }else{
        reject();
      }
    });
  }

  create(params) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/coworkers/', {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  update(uuid, params) {
    return new Promise((resolve, reject) => {
      this.instance.put('backoffice/coworkers/'+uuid, {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  delete(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.delete('backoffice/coworkers/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  listRoles(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/roles'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getRole(uuid) {
    return new Promise((resolve, reject) => {
      if(uuid){
        this.instance.get('backoffice/roles/'+uuid).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      }else{
        reject();
      }
    });
  }

  adminList(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/admin/coworkers'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
}

let service =  new CoworkersService();
export default service;